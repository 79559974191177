import Vue from 'vue'
import ElementUI from 'element-ui';
import ReturnButton from '@/components/ReturnButton/index.vue'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'

import './assets/css/main.css'

Vue.config.productionTip = false

Vue.component('return-button', ReturnButton);

Vue.use(ElementUI);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')